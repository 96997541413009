import { render, staticRenderFns } from "./excellentwork-stats.vue?vue&type=template&id=163a2faa&scoped=true"
import script from "./excellentwork-stats.vue?vue&type=script&lang=js"
export * from "./excellentwork-stats.vue?vue&type=script&lang=js"
import style0 from "./excellentwork-stats.vue?vue&type=style&index=0&id=163a2faa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163a2faa",
  null
  
)

export default component.exports