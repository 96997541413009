<template>
    <!-- 管理员- 优秀作业统计 -->
    <div class="course wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align-between">
            <el-form-item label="">
            </el-form-item>
            <div class="download-inline">
                <span class="export-btn csp" style="margin-right:10px" @click="exportFile">
                    <i class="export-icon"></i>导出</span>
            </div>
        </el-form>
        <div class="table_section">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="number" label="序号" width="80" align="center" type="index" :index="indexMethod">
                </el-table-column>
                <el-table-column prop="userName" label="姓名" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="phone" label="手机号" align="center" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="area" label="地区" align="center" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="schoolName" label="学校" align="center" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="count" label="优秀作业数量" align="center" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="120">
                    <template slot-scope="scope">
                        <el-button @click="toDetail(scope.row)" type="text" size="small">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                :pageSizes="[10,20,30]" @pagination="handlePageChange">
            </pagination>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                formInline: {
                    datetimerange: '',
                    search: '',
                },
            };
        },
        methods: {
            // 序号
            indexMethod(index) {
                return this.pages.currentPage > 1 ?
                    (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
                    index + 1
            },
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.projectWorkRanking();
            },
            // 去个人优秀作业详情页面
            toDetail(row) {
                this.$router.push({
                    path: `/excellentworkDetail/${row.userId}`,
                    query: {
                        breadNumber: 4,
                    }
                })
            },
            // 获取优秀作业列表
            async projectWorkRanking() {
                let pramas = {
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    projectId: this.$store.state.user.currentProject.id,
                }
                let resData = await this.$Api.Project.projectWorkRanking(pramas);
                console.log(resData.data);
                this.tableData = resData.data.list
                this.pages.total = resData.data.totalCount
            },
            // 导出数据
            async exportFile() {
                this.$message.info('正在导出...')
                let id = this.$store.state.user.currentProject.id
                window.location.href =
                    `${this.commonbaseURL}/learn/project/projectRanking/export?projectId=${id}`;
            },
        },
        mounted() {
            this.projectWorkRanking();
        },
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .course {
        padding: 20px 25px;
        box-sizing: border-box;

        .switch {
            .switch-btn-warpper {
                height: 42px;
                display: inline-block;
                margin-right: 10px;
            }

            .switch-btn {
                padding: 0 31px;
                font-size: 15px;
                line-height: 36px;
            }

            .active {
                background-image: url("~@/assets/images/admin/course-switch.png");
                background-size: 100% 100%;

                .switch-btn {
                    color: #508ef9;
                }
            }
        }

        .demo-form-inline {
            margin: 15px 0;

            .date-picker {
                width: 320px;
            }

            @media screen and (min-width:1250px) {
                margin: 20px 0;

                .date-picker {
                    width: 400px;
                }
            }
        }

        .download-inline {
            .export-btn {
                color: #595959;
            }

            .export-icon {
                display: inline-block;
                background-image: url("~@/assets/images/admin/export.png");
                background-size: 100% 100%;
                width: 17px;
                height: 16px;
                margin-right: 8px;
                vertical-align: -2px;
            }
        }


        .table_section {
            /deep/.el-table th {
                &>.cell {
                    white-space: pre-line;
                }
            }
        }

    }
</style>